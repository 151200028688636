<template>
  <div class="login">
    <header class="page-header">欢迎登录{{ title }}</header>
    <main class="page-body">
      <div class="label">{{ $t('Login.ncp8ml') }}</div>
      <van-field
        v-model="username"
        clearable
        :placeholder="$t('Login.vueVLyW-zKejH444LZGT9y6-')"
      ></van-field>
      <div class="label">{{ $t('Login.nmoky3') }}</div>
      <van-field
        v-model="password"
        :type="showPassword ? 'input' : 'password'"
        :placeholder="$t('Login.vuenM-iO4iRRW4Iq6WCnSsPg')"
      >
        <template #right-icon>
          <van-icon
            :name="showPassword ? 'closed-eye' : 'eye-o'"
            @click="showPassword = !showPassword"
          />
        </template>
      </van-field>
      <div class="forget" @click="$router.push({ name: 'ResetPassword' })">
        {{ $t('Login.vue6Yn-B5G16XjDz25117JSV') }}
      </div>
      <van-button type="info" @click="onLogin" :disabled="!valid">
        {{ $t('Login.vue-lHuUIdhEZT6h3Q8Lrgnp') }}
      </van-button>
      <div class="sign-up x-center">
        <span>{{ $t('Login.vueygJSbYR_JprUZ8Rt8eCcA') }}</span>
        <router-link class="theme-color" :to="{ name: 'SignUp' }">
          {{ $t('Login.vueTheZurgOX1AAHawZITosf') }}
        </router-link>
        <router-link
          class="theme-color"
          style="padding-left: 10px"
          :to="{ name: 'Home' }"
        >
          {{ $t('Login.返回主页') }}
        </router-link>
      </div>
    </main>
  </div>
</template>
<script>
import { login } from '@/api/account.js';
import { APP_TITLE } from '@/api/index.js';
export default {
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      title: APP_TITLE,
    };
  },
  computed: {
    valid() {
      return this.username && this.password;
    },
  },
  methods: {
    onLogin() {
      this.$toast.loading({
        duration: 0,
      });
      login({ username: this.username, password: this.password })
        .then(res => {
          this.$toast.clear();
          if (res.Result) {
            this.$store.dispatch('getUserInfo');
            this.$router.replace({
              name: 'Home',
            });
          } else {
            this.$toast.fail(res.Info);
          }
        })
        .catch(() => {
          this.$toast.clear();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.login {
  background-color: #fff;
}
.page-header {
  justify-content: center;
  font-size: 21px;
  font-weight: bold;
}
.page-body {
  padding: rem(60px);
}
.label {
  font-size: 16px;
  margin-bottom: rem(30px);
}
.van-field {
  border-radius: rem(25px);
  margin-bottom: rem(40px);
  background-color: #f7f7fc;
  font-size: 16px;
  padding-top: rem(24px);
  padding-bottom: rem(24px);
}
.forget {
  color: #9c9bb4;
  text-align: right;
}
.van-button {
  height: rem(100px);
  line-height: rem(100px);
  width: 100%;
  border-radius: rem(50px);
  margin-top: rem(156px);
  font-size: 16px;
}
.sign-up {
  color: #b2b2c4;
  margin-top: rem(212px);
}
</style>
